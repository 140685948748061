import React from "react";
import {colors} from "../Theme";

export const BHeader = () => {
  return (
    <div style={styles.header}>
      <h3 style={styles.headerTitle}> BBIRLE • VOL 1</h3>
      <BMenu/>
    </div>
  );
};

const BMenu = () => {
  return (
    <div className="menu" style={styles.navbar}>
      <ul className="menuList" style={styles.navbarItems}>
        <li>
          {/* <li style={styles.navbarItem}> */}
          <a href={"/"} style={styles.navbarLink}>
            OPPGAVER
          </a>
        </li>
        <li>
          <a href={"status"} style={styles.navbarLink}>
            STATUS
          </a>
        </li>
        <li>
          <a href={"map"} style={styles.navbarLink}>
            KART
          </a>
        </li>
        <li>
          <a href={"rules"} style={styles.navbarLink}>
            REGLER
          </a>
        </li>
      </ul>
    </div>
  );
};

// TODO::Nanlind - Fiks font ikke lastes ned

// Other possible solution
//  styles: {header: CSS.properties }
const styles = {
  header: {
    textAlign: "center" as const,
    fontSize: "calc(10px + 2vmin)",

    fontFamily: "VT323",
    color: colors.headerText,
  },
  headerTitle: {},
  navbar: {
    paddingRight: "24px",
    borderTop: "#dedcd5 solid 1px",
    borderBottom: "#dedcd5 solid 1px",
  },
  navbarItems: {
    display: "flex",
    justifyContent: "space-evenly",
    listStyle: "none",
    fontSize: "24px",
  },
  navbarLink: {
    color: colors.headerText,
  },
};
