import React from "react";
import "../style.css";
import { spacing } from "../Theme";
import styled from "styled-components";
import { teamStatus } from "../tempVariables";

interface StatusProps {
  teamName: string;
  hp: number;
  numHits: number;
  numMissed: number;
}

export const BStatus = () => {
  const status = teamStatus; // temp

  return (
    <div style={styles.statusDiv}>
      <h1 style={styles.statusTitle}> Status</h1>
      <StatusTable status={status} />
    </div>
  );
};

const StatusTable = ({ status }: { status: StatusProps }) => {
  return (
    <table style={styles.statusTable}>
      <TRow>
        <td>Team </td>
        <td> {status.teamName}</td>
      </TRow>
      <TRow>
        <td>HP</td>
        <td>{status.hp}</td>
      </TRow>
      <TRow>
        <td>Shots fired</td>
        <td>{status.numHits + status.numMissed}</td>
      </TRow>
      <TRow>
        <td>Hits</td>
        <td>{status.numHits}</td>
      </TRow>
      <TRow>
        <td>Missed</td>
        <td>{status.numMissed}</td>
      </TRow>
    </table>
  );
};

const styles = {
  statusDiv: {
    padding: spacing.defaultPadding,
  },
  statusTitle: {
    // color: "yellow",
  },
  statusTable: {
    minWidth: "40%",
  },
  statusTableRow: {
    display: "flex",
    justifyContent: "space-between",
  },
};

// Styling under brukes når pseudoklasser og barn skal nås
const TRow = styled.tr`
  display: flex;
  justify-content: space-between;

  td:nth-child(1) {
    font-weight: bold;
  }
`;
