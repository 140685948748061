import ErrorPage from "./error-page";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {BHeader} from "./Components/BHeader";
import {BRules} from "./Components/BRules";
import {BMap} from "./Components/BMap";
import {BStatus} from "./Components/BStatus";
import {BTasks} from "./Components/BTasks";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Login} from "./Components/Login";
import {useAuth} from "./hooks/useAuth";

const queryClient = new QueryClient()

export const App = () => {
  const {auth} = useAuth();
  if (!auth) {
    return <Login/>
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BTasks/>,
      errorElement: <ErrorPage/>,
    },
    {
      path: "/status",
      element: <BStatus/>,
      errorElement: <ErrorPage/>,
    },
    {
      path: "/map",
      element: <BMap/>,
      errorElement: <ErrorPage/>,
    },
    {
      path: "/rules",
      element: <BRules/>,
      errorElement: <ErrorPage/>,
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <BHeader/>
        <RouterProvider router={router}/>
      </>
    </QueryClientProvider>
  )
}
